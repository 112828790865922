import React, { useRef, useState } from 'react';
import './App.css';
// import './index.css'
import firebase from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';
import 'firebase/firestore';
import ChatRoom from './components/ChatRoom'
import 'firebase/auth';
import Login from './components/Login';
import Logout from './components/Logout'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
firebase.initializeApp({
  apiKey: "AIzaSyBQ2mi4pyDIRXeYJb1hGjKwCfFyMlmdDvU",
  authDomain: "web-project-c6efc.firebaseapp.com",
  projectId: "web-project-c6efc",
  storageBucket: "web-project-c6efc.appspot.com",
  messagingSenderId: "822411482345",
  appId: "1:822411482345:web:58de4c3fc1b7c6f4f54c78",
  measurementId: "G-NT7V7X4SW7"
})
const auth = firebase.auth();
const firestore = firebase.firestore();
function App() {
  const [user] = useAuthState(auth);
  return (
    <Router>
      <Switch>
        <Route path="/" exact children={<Login firebase={firebase} auth={auth} loggedIn={user ? true : false} />} />
        <Route path="/rooms/:id" children={user ? <ChatRoom auth={auth} firestore={firestore} firebase={firebase} /> : <Login firebase={firebase} auth={auth} loggedIn={user ? true : false} />} />
      </Switch>
    </Router>
  );
}
export default App;
